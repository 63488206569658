import { useTheme } from 'styled-components';

import { IconProps } from '..';
import { Svg } from '../styles';

export const PlusIcon = ({
  height = '24px',
  width = '24px',
  fillColor: initFillColor,
}: IconProps) => {
  const theme = useTheme();
  const fillColor = initFillColor || theme.colors.primary[500];
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="plus-icon"
    >
      <g clipPath="url(#clip0_21456_21175)">
        <path d="M19 13H13V19H11V13H5V11H11V5H13V11H19V13Z" fill={fillColor} />
      </g>
      <defs>
        <clipPath id="clip0_21456_21175">
          <rect width={width} height={height} fill={fillColor} />
        </clipPath>
      </defs>
    </Svg>
  );
};
