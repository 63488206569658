import React from 'react';
import { useTheme } from 'styled-components';

import { IconProps } from '..';
import { Svg } from '../styles';

export const DealManagementIcon = ({
  width = '24px',
  height = '24px',
  fillColor: initFillColor,
}: IconProps) => {
  const theme = useTheme();
  const fillColor = initFillColor || theme.colors.primary[500];
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 25 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.30469 1.25304C8.30469 0.853801 8.62834 0.530151 9.02758 0.530151H15.6782C16.0774 0.530151 16.4011 0.853801 16.4011 1.25304V4.43377H14.9553V1.97593H9.75047V4.43377H8.30469V1.25304Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 4.72301C0.5 4.32377 0.82365 4.00012 1.22289 4.00012H23.7771C24.1763 4.00012 24.5 4.32377 24.5 4.72301V18.8917C24.5 19.2909 24.1763 19.6146 23.7771 19.6146H1.22289C0.82365 19.6146 0.5 19.2909 0.5 18.8917V4.72301ZM1.94578 5.44591V18.1688H23.0542V5.44591H1.94578Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.57422 18.8916V4.7229H8.02V18.8916H6.57422Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9805 18.8916V4.7229H18.4263V18.8916H16.9805Z"
        fill={fillColor}
      />
    </Svg>
  );
};
