import { useTheme } from 'styled-components';

import { IconProps } from '..';
import { Svg } from '../styles';

export const SearchIcon = ({
  width = '24',
  height = '24',
  fillColor,
}: IconProps) => {
  const theme = useTheme();
  if (!fillColor) {
    fillColor = theme.colors.primary[500];
  }
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="search-icon-id"
    >
      <path
        d="M9.89648 16.1614C8.07982 16.1614 6.54232 15.5322 5.28398 14.2739C4.02565 13.0155 3.39648 11.478 3.39648 9.66138C3.39648 7.84471 4.02565 6.30721 5.28398 5.04888C6.54232 3.79054 8.07982 3.16138 9.89648 3.16138C11.7132 3.16138 13.2507 3.79054 14.509 5.04888C15.7673 6.30721 16.3965 7.84471 16.3965 9.66138C16.3965 10.3947 16.2798 11.0864 16.0465 11.7364C15.8132 12.3864 15.4965 12.9614 15.0965 13.4614L20.6965 19.0614C20.8798 19.2447 20.9715 19.478 20.9715 19.7614C20.9715 20.0447 20.8798 20.278 20.6965 20.4614C20.5132 20.6447 20.2798 20.7364 19.9965 20.7364C19.7132 20.7364 19.4798 20.6447 19.2965 20.4614L13.6965 14.8614C13.1965 15.2614 12.6215 15.578 11.9715 15.8114C11.3215 16.0447 10.6298 16.1614 9.89648 16.1614ZM9.89648 14.1614C11.1465 14.1614 12.209 13.7239 13.084 12.8489C13.959 11.9739 14.3965 10.9114 14.3965 9.66138C14.3965 8.41138 13.959 7.34888 13.084 6.47388C12.209 5.59888 11.1465 5.16138 9.89648 5.16138C8.64648 5.16138 7.58398 5.59888 6.70898 6.47388C5.83398 7.34888 5.39648 8.41138 5.39648 9.66138C5.39648 10.9114 5.83398 11.9739 6.70898 12.8489C7.58398 13.7239 8.64648 14.1614 9.89648 14.1614Z"
        fill={fillColor}
      />
    </Svg>
  );
};
