import { useTheme } from 'styled-components';

import { IconProps } from '..';
import { Svg } from '../styles';

export const CloseIcon = ({
  height = '24px',
  width = '24px',
  fillColor: initFillColor,
}: IconProps) => {
  const theme = useTheme();
  const fillColor = initFillColor || theme.colors.primary[500];
  return (
    <Svg
      data-testid="close-icon-id"
      viewBox="0 0 24 24"
      height={height}
      width={width}
    >
      <g clipPath="url(#clip0_4001_6220)">
        <path
          d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_4001_6220">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};
