import { useTheme } from 'styled-components';

import { IconProps } from '..';
import { Svg } from '../styles';

export const EyeClosedIcon = ({
  height = '24px',
  width = '24px',
  fillColor,
}: IconProps) => {
  const theme = useTheme();
  if (!fillColor) {
    fillColor = theme.colors.primary[500];
  }
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="eye-closed-icon"
    >
      <g clipPath="url(#clip0_19027_53278)">
        <path
          d="M12 6.5C15.79 6.5 19.17 8.63 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C4.83 8.63 8.21 6.5 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
          fill={fillColor}
        />
        <rect
          x="21.1118"
          y="3.71053"
          width="1"
          height="24.5032"
          rx="0.5"
          transform="rotate(49.7544 21.1118 3.71053)"
          fill={fillColor}
          stroke={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_19027_53278">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};
