import { useTheme } from 'styled-components';

import { IconProps } from '..';
import { Svg } from '../styles';

export const MoreIcon = ({
  width = '24px',
  height = '24px',
  fillColor: initFillColor,
}: IconProps) => {
  const theme = useTheme();
  const fillColor = initFillColor || theme.colors.primary[500];
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_35314_45498)">
        <path
          d="M4.5 7.5C3.675 7.5 3 8.175 3 9C3 9.825 3.675 10.5 4.5 10.5C5.325 10.5 6 9.825 6 9C6 8.175 5.325 7.5 4.5 7.5ZM13.5 7.5C12.675 7.5 12 8.175 12 9C12 9.825 12.675 10.5 13.5 10.5C14.325 10.5 15 9.825 15 9C15 8.175 14.325 7.5 13.5 7.5ZM9 7.5C8.175 7.5 7.5 8.175 7.5 9C7.5 9.825 8.175 10.5 9 10.5C9.825 10.5 10.5 9.825 10.5 9C10.5 8.175 9.825 7.5 9 7.5Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_35314_45498">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </Svg>
  );
};
