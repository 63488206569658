import theme from 'stories/theme';
import styled from 'styled-components';
import { renderSize } from 'utils/helpers/renderSize';
import { SpacingType, spacingUtils } from 'utils/helpers/spacingUtils';

interface SvgProps {
  height: string | number;
  width: string | number;
}

export const Svg = styled.svg<SvgProps>`
  min-height: ${({ height }) => height};
  min-width: ${({ width }) => width};
  max-height: ${({ height }) => height};
  max-width: ${({ width }) => width};
`;

interface IconButtonProps extends SpacingType {
  variant?: 'rounded' | 'square';
  width?: string | number;
  height?: string | number;
}

export const IconButton = styled.div<IconButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grey[200]};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.grey[300]};
  }

  ${({ variant }) => `
		border-radius: ${variant === 'rounded' ? '100%' : theme.styles.borderRadius};
	`}

  ${({ width = 40 }) => `
		width: ${renderSize(width)};
	`}

		${({ height = 40 }) => `
		height: ${renderSize(height)};
	`}

  ${spacingUtils}
`;
